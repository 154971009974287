import React, {useEffect} from "react" 
import BroucherForm from "../../forms/broucher-form"
import "./BroucherModal.scss"
const BroucherModal = ({isModalOpen, closeModal, brochure}) => {
    if (!isModalOpen) {
        return null
    }

    const onFormSubmit = (e) => {
        e.preventDefault()
        closeModal();
    }

  return (
    <div className="broucher-model-wrapper">
      <div className="broucher-model-container">
        <h3 className="title">Get your Brochure</h3>
        <p className="description">
        Submit your details and get a Free PDF Brochure
        </p>
        <div className="form-container">
          <BroucherForm brochure={brochure}/> 
          <div className="close-btn" onClick={closeModal}>
            <i class="icon-close"></i>
          </div>
        </div>
      </div>
      <div className="modal-backdrop" onClick={closeModal}></div>
    </div>
  )
}

export default BroucherModal
