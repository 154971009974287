import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useWindowSize } from "../../hooks/window-size"
import PlanCard from "./PlanCard"
import Slider from "react-slick"
import { uniq } from "lodash"
import { AnimationOnScroll } from "react-animation-on-scroll"
import "./FloorPlan.scss"
import Select from 'react-select'
import parse from "html-react-parser"

const FloorPlan = ({ plans }) => {
	const [windowWidth] = useWindowSize()
	const [planList, setPlanList] = useState(plans && plans.filter((p) => p.Bedroom == 1))
	const [active, setActive] = useState(1)
	if (plans && plans.length == 0) return null;

	const groupByPlan = uniq(plans.map((p) => p.Bedroom));
	const NextArrow = ({ onClick }) => {
		return (
			<button className="slick-next" onClick={onClick}>
				<i className="icon-pagination-right"></i>
			</button>
		);
	};
	const PrevArrow = ({ onClick }) => {
		return (
			<button className="slick-prev" onClick={onClick}>
				<i className="icon-pagination-left"></i>
			</button>
		);
	};

	const changeBed = bed => {
		setActive(bed)
		setPlanList(plans.filter((p) => p.Bedroom == bed))
	}

	let settings = {
		dots: false,
		arrows: true,
		infinite: false,
		speed: 500, 
		slidesToShow: 4,
		slidesToScroll: 1,
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					dots: true,
					arrows: false,
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 991,
				settings: {
					dots: true,
					arrows: false,
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			}
		],
	}
	return (
		<section className="floor-plans" id="floor-plans">
			<Container>
				<Row>
					<Col>
						<div className="heading">
							<h3>Floor Plan</h3>
							<div className="right">
								{windowWidth > 767 && groupByPlan && groupByPlan.length > 0 && groupByPlan.map((bed) => {
									return (
										<a href="javascript:void(0)" className={`type ${bed == active ? "active" : ""}`} onClick={() => { changeBed(bed) }}>{bed} Bedroom</a>
									)
								})
								}
								{windowWidth <= 767 && groupByPlan && groupByPlan.length > 0 &&

									<Select
										options={groupByPlan.map(bed => ({ value: bed, label: `${bed} Bedroom` }))}
										placeholder={"Select Bedroom"}
										onChange={(e) => { changeBed(e.value) }}
										isSearchable={false}
										className="react-select"
										classNamePrefix="react-select"
									>
									</Select> 
								}

							</div>

						</div>
						<div className="plans-wrap">

							<Slider {...settings} className="custom-arrow-slider">
								{planList.map((plan, index) => (
									<PlanCard key={index} planData={plan} />
								))}
							</Slider>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default FloorPlan
