import React, { useState } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap"
import { Link } from "gatsby"
import $ from "jquery";
import "./DetailsTab.scss"
import parse from "html-react-parser"
import { NewsDate } from "../../../components/common/site/functions"
import { CurrConvertPrice } from '../../../components/currency-converter/CurrConvertPrice'
const detailTabContent = {
  location: "Business Bay",
  developer: "Select Group",
  developmentType: "Waterfront Community",
  completionDate: "December 2024",
  price: "AED 639,000",
  propertyDetails:
    "Peninsula will stand out as the most unique waterfront destination strategically located in the heart of Business Bay — the Central Business District of Dubai (CBD). The modern development is a perfect example of affordable luxury in Dubai and will be located by the Canal. Business Bay is one of the most desirable communities for property investors due to its central location, great city access, and high potential rental returns. Easy 4 step payment plan available!",
}


const DetailsTab = ({ offplan }) => {

  const [readMore, setReadMore] = useState(offplan.description.length >= 1000)

  const readmore_expand = (readMore) => {
    setReadMore(!readMore)
    if (!readMore) {
      $('html, body').animate({ scrollTop: $(".description-text").offset().top - 200 }, 1000);
    }
  }

  return (
    <div className="details-tab">
      <div className="right-block">
        <h2>About this Project</h2>
        {offplan.description &&
          <div className="description-text">
            <div className={`details-info property-description-details ${readMore ? "read_more" : "read_less"}`}>


              <div className={`expand ${readMore ? "panel-closed" : ""}`}>
                {readMore ?
                  <>{parse(offplan.description.substr(0, 1000))}</>
                  :
                  <>{parse(offplan.description)}</>
                }
              </div>

              {offplan.description.length >= 1000 &&
                <a href="javascript:void(0)" className="readmore_expand" onClick={() => readmore_expand(readMore)}>{readMore ? "Read more" : "Read Less"}</a>
              }
            </div>
          </div>
        }

        {/* {offplan.Details_Accordian && offplan.Details_Accordian.length > 0 &&
          <Accordion>
            {offplan.Details_Accordian.map((item, index) => (
              <Accordion.Item eventKey={index}>
                <Accordion.Header>{parse(item.Title)}</Accordion.Header>
                <Accordion.Body>{parse(item.Content)}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        }
        <div className="btns-wrap">
          {offplan.upload_brochure && offplan.upload_brochure.length > 0 && (
            <Link
              href={offplan.upload_brochure[0].url}
              className="btn"
              target="_blank"
            >
              Brochure <i className="icon-right"></i>
            </Link>
          )}
          {offplan.upload_floorplan && offplan.upload_floorplan.length > 0 && (
            <Link
              href={offplan.upload_floorplan[0].url}
              className="btn"
              target="_blank"
            >
              Floorplans <i className="icon-right"></i>
            </Link>
          )}
        </div> */}
      </div>
      <ul>
        <li>
          <i className="icon icon-money"></i>
          <div>
            <label>Prices From</label>
            <p className="detail-wrap">{CurrConvertPrice(offplan.price.toLocaleString())}</p>
          </div>

        </li>
        <li>
          <i className="icon icon-calendar"></i>
          <div>
            <label>Handover</label>
            <p className="detail-wrap">{NewsDate(offplan.completion_date)}</p>
          </div>

        </li>
        <li>
          <i className="icon icon-map-pin"></i>
          <div>
            <label>Location</label>
            <p className="detail-wrap">{offplan.location}</p>
          </div>
        </li>
        <li>
          <i className="icon icon-crop"></i>
          <div>
            <label>Developer</label>
            <p className="detail-wrap">{offplan.developer}</p>
          </div>
        </li>
        <li>
          <i className="icon icon-percent"></i>
          <div>
            <label>Payment Plan</label>
            <p className="detail-wrap">{offplan.payment_plan}</p>
          </div>
        </li>

        {/* {offplan?.QR_Code_Image && offplan?.QR_Code_Image[0]?.url &&
          <li className="permit-code-offplan">
            <label>Permit QR Code:</label>
            <span className="detail-wrap permit-qr-img"> <img src={offplan?.QR_Code_Image[0]?.url} alt={`${offplan?.QR_Code_Image[0]?.alternativeText ? offplan.QR_Code_Image[0].alternativeText : "QR code"}`} className="QR-code" /></span>
          </li>
        } */}
      </ul>
    </div>
  )
}

export default DetailsTab
