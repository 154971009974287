import * as React from "react";
import { useWindowSize } from "../../hooks/window-size";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import ContactDetails from "../ContactDetails/RegiterDetails";
import ContactForm from "../ContactForm/RegisterForm";
import { AnimationOnScroll } from "react-animation-on-scroll";
import MemberCard from "../MemberCard/MemberCard";

import "./Contact.scss";
import { graphql, useStaticQuery } from "gatsby";
const Contact = ({ offplan }) => {
  const [windowWidth] = useWindowSize();

  const data = useStaticQuery(graphql`
    query GetContactWithID {
      glstrapi {
        team(id: "6256c4b0e77511031a9567a5") {
          Designation
          URL
          Email
          id
          Phone
          Name
          imagetransforms
          languages
          Image {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 180
                  quality: 80
                  layout: FIXED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  `)

  const staff = offplan?.Select_Contact?.Name ? offplan?.Select_Contact : data.glstrapi.team;
  const waMsg = `${offplan.name} – Hi ${staff.Name.split(" ")[0]}, I came across the Project ${offplan.name} and would like further information`

  return (
    <>
      <section className="contact-wrap regiter-interest">
        <Container>
          <Row>
            <Col className="contact-title">
              <h2 className="register-interest-h2">Register Your Interest</h2>
              <p className="desc">
                Please leave your contact details and we'll be in touch.
              </p>
              {windowWidth > 992&& offplan.whatsapp && offplan.email &&(
                <>
                  <a className="action" href={`https://wa.me/${offplan.whatsapp}?text=${offplan.name}`} target="_blank" ><i className="icon-whatsapp"></i> Click to WhatsApp  </a>
                  <div className="hr-tag"></div>
                  <a className="action" href={`mailto:${offplan.email}`} target="_blank"><i className="icon-envelope"></i> {offplan.email}  </a>
                </>
              )}

              {windowWidth < 992 ? (
                <>
                  <Col>
                    <ContactForm staff={staff} />
                  </Col>
                </>
              ) : null}
              {!offplan.permit_number && staff &&
                <MemberCard staff={staff} waMsg={waMsg} />
              }
              {windowWidth < 992 && offplan.whatsapp && offplan.email &&(
                <div className="ctas">

                  <a className="action" href={`https://wa.me/${offplan.whatsapp}?text=${offplan.name}`} target="_blank" ><i className="icon-whatsapp"></i> Click to WhatsApp  </a>
                  <div className="hr-tag"></div>
                  <a className="action" href={`https://wa.me/${offplan.whatsapp}?text=${offplan.name}`} target="_blank"><i className="icon-envelope"></i> {offplan.email}  </a>
                </div>
              )}

              {offplan?.QR_Code_Image && offplan?.QR_Code_Image?.length > 0 && offplan.permit_number &&
                <div className="dealer-info ">
                  <div className="dealer-wrap">
                    <div className="img-wrap zoom">
                      <img src={offplan?.QR_Code_Image[0]?.url} alt={`${offplan?.QR_Code_Image[0]?.alternativeText ? offplan.QR_Code_Image[0].alternativeText : "QR code"}`} className="QR-code" />
                    </div>
                    <div className="dealer-right top-right d-md-none">
                      <div className="permit">DLD Permit Number</div>
                      <div className="permit_number">{offplan.permit_number}</div>
                      <div className="permit_verifier">{offplan.permit_verifier}</div>
                    </div>

                    <div className="dealer-right bottom-right d-none d-md-flex">
                      <div className="permit">DLD Permit Number</div>
                      <div className="permit_number">{offplan.permit_number}</div>
                      <div className="permit_verifier">{offplan.permit_verifier}</div>
                    </div>
                  </div>
                </div>
              }

            </Col>
            {windowWidth >= 992 ? (
              <>
                <Col>
                  <ContactForm staff={staff} />
                </Col>
              </>
            ) : null}
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Contact;
