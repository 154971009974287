import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick"
import { AnimationOnScroll } from "react-animation-on-scroll"
import { scrollToElement } from "../common/site/functions"
import "./OffPlanDeveloper.scss"
import parse from "html-react-parser"

const OffPlanDeveloper = ({ block }) => {
  if (!block) return null;

  const goToRegister = () => {
    const element = document.getElementById("register-interest-form")
    scrollToElement(element)
  }
  

  return (
    <section className="developer" id="developer">
      <Container>
        <Row className="align-items-center">

          <Col lg={7}>
            <div className="content">
              {block?.Logo &&
                <img src={block?.Logo?.url} alt="Developer logo" />
              }

              {block.Content &&
                <>{parse(block.Content)}</>
              }
              <button className="btn" onClick={goToRegister} >Register your Interest <i class="icon-right"></i></button>
            </div>

          </Col>
          <Col lg={5}>
            {block?.Image &&
              <img src={block?.Image?.url} alt="Broucher" className="fullImg" />
            }
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OffPlanDeveloper
