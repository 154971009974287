import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import PlanCard from "./PlanCard"
import Slider from "react-slick"
import { AnimationOnScroll } from "react-animation-on-scroll"
import "./OffPlanAmenities.scss"
import parse from "html-react-parser"

const OffPlanAmenities = ({ amenities }) => {
  if (!amenities) return null;
  const NextArrow = ({ onClick }) => {
    return (
      <button className="slick-next" onClick={onClick}>
        <i className="icon-pagination-right"></i>
      </button>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <button className="slick-prev" onClick={onClick}>
        <i className="icon-pagination-left"></i>
      </button>
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    autoplay: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <section className="amenities" id="amenities">
      <Container>
        <Row>
          <Col>
            <h3>{amenities.Module_Title}</h3>
            <p className="description">{parse(amenities.Module_Description)}</p>
            <div className="plans-wrap">
              <Slider {...settings} className="btn-fix custom-arrow-slider">
                {amenities.Tile_Image_Items.map((plan, index) => (
                  <PlanCard key={index} planData={plan} />
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OffPlanAmenities
