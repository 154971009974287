import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick"
import { AnimationOnScroll } from "react-animation-on-scroll"
import PropertyMap from "../PropertyMap/PropertyMap";
import BroucherModal from "./BroucherModal/BroucherModal";

import "./OffPlanBroucher.scss"
import parse from "html-react-parser"

const OffPlanBroucher = ({ image, name, brochure }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)
  const openModal = () => setIsModalOpen(true)

  if (!brochure) return null;
  return (
    <section className="broucher-plans" id="broucher">
      <Container>
        <Row className="align-items-center">
          <Col lg={5}>
            {image &&
              <img src={image?.url} alt="Broucher" />
            }
          </Col>
          <Col lg={7}>
            <div className="content">
              <p className="sm">Broucher</p>
              <h3>Get This Project Brochure</h3>
              <p>All you need to know {name}</p>
              {brochure && brochure?.length > 0 &&
                <button className="btn" onClick={openModal}>Download Broucher <i class="icon-right"></i></button>
              }
            </div>


          </Col>
        </Row>
      </Container>
      {brochure && brochure?.length > 0 &&
        <BroucherModal isModalOpen={isModalOpen} closeModal={closeModal} brochure={brochure[0]?.url} />
      }
    </section>
  )
}

export default OffPlanBroucher
