import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import PlanCard from "./PlanCard"
import Slider from "react-slick"
import { AnimationOnScroll } from "react-animation-on-scroll"
import "./PaymentPlan.scss"
import parse from "html-react-parser"

const OffPlanPaymentPlan = ({ plans }) => {
  if (!plans) return null;
  let settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <section className="offplan_payment" id="offplan_payment">
      <Container>
        <Row>
          <Col>
            <h3>{plans.Module_Title}</h3>
            <p className="description">{parse(plans.Module_Description)}</p>
            <div className="plans-wrap">
              <Slider {...settings} className="btn-fix  custom-arrow-slider">
                {plans.Module_Items.map((plan, index) => (
                  <PlanCard key={index} planData={plan} />
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OffPlanPaymentPlan
