import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick"
import { AnimationOnScroll } from "react-animation-on-scroll"
import PropertyMap from "../PropertyMap/PropertyMap";
import "./OffPlanLocation.scss"
import parse from "html-react-parser"
import { useWindowSize } from "../../hooks/window-size"

const OffPlanLocation = ({ content, lat, lng }) => {
  const [windowWidth] = useWindowSize()
  if (!content) return null;
  return (
    <section className="offplan_location" id="offplan_location">
      <Container>
        <Row className="align-items-center">
          <Col lg={5}>
            <h3>{content.Title}</h3>
            <p>{parse(content.Description)}</p>

          </Col>
          <Col lg={7}>
            <div className="google-maps" id="offplan-map">
              <PropertyMap lat={lat} lng={lng} height={windowWidth < 768 ? "220px" : "500px"} />

            </div>

          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OffPlanLocation
