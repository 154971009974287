import React from "react"
import "./PlanCard.scss"

const PlanCard = ({ planData }) => {
  return (
    <div className="payment-plan-card">
      <h2>{`${planData.Title}`}</h2>
      <p>{planData.Description}</p>
    </div>
  )
}

export default PlanCard
