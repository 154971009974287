import React, { useState } from "react"
import Header from "../components/Header/Header"
import { graphql } from "gatsby"
import Footer from "../components/Footer/Footer"
import OffPlanBanner from "../components/OffPlanBanner/OffPlanBanner"
import OffPlanDetails from "../components/OffPlanDetails/OffPlanDetails"
import OffPlanPaymentPlan from "../components/OffPlanPaymentPlan/OffPlanPaymentPlan"
import FloorPlan from "../components/FloorPlan/FloorPlan"
import OffPlanAmenities from "../components/OffPlanAmenities/OffPlanAmenities"
import OffPlanLocation from "../components/OffPlanLocation/OffPlanLocation"
import OffPlanBroucher from "../components/OffPlanBroucher/OffPlanBroucher"
import OffPlanDeveloper from "../components/OffPlanDeveloper/OffPlanDeveloper"
import OffPlanImageGallery from "../components/OffPlanImageGallery/ImageGallery"
import RegisterInterset from "../components/Contact/Register"
import SEO from "../components/Seo/seo";
import BrandLogo from "../images/logo.svg";
// import GoogleMapReact from "google-map-react"
// import Help from "../components/Help/Help"
// import PropertyMap from "../components/PropertyMap/PropertyMap";
// import Btn from "../components/BottomBtn/BtnOffplan";
// import Marker from "../components/MapMarker/Marker"
// import OtherOffPlan from "../components/OtherOffPlan/OtherOffPlan"
// import VideoBox from "../components/VideoBox/VideoBox"


const OffPlanDetailsTemplate = props => {
  const offPlanData = props.data.glstrapi.offplan
  var setoffplansearches = props.location.state

  let whatsAppMessage = ""
  console.log('offPlanData', offPlanData)
  if (offPlanData && typeof window !== "undefined") {
    const urlSections = window.location.href.split("/")
    const pageurl = typeof window !== "undefined" ? window.location.href : ""

    whatsAppMessage = `Hello, I would like to get more information about this property you posted on ${urlSections[2]}: Name: ${offPlanData.name} Location: ${offPlanData.location} Developer: ${offPlanData.developer} Type: ${offPlanData.property_type}  Price: ${offPlanData.price} AED  Link: ${pageurl}`
  }
  var metatitle = offPlanData.name + " By " + offPlanData.developer
  var metadesc = "Know the details of " + offPlanData.name + " in " + offPlanData.location + " at AED " + offPlanData.price.toLocaleString() + ". Register to get assistance in finding the right " + offPlanData.name + " for you."

  return (
    <div className="off-plan-details-page">
      <SEO title={metatitle} description={metadesc} image={BrandLogo} />
      <Header />
      <OffPlanBanner offplan={offPlanData} setoffplansearches={setoffplansearches} />
      <OffPlanDetails offplan={offPlanData} />
      <OffPlanAmenities amenities={offPlanData.amenities} />
      <OffPlanLocation content={offPlanData.Location_Block} lat={parseFloat(offPlanData.latitude)} lng={parseFloat(offPlanData.longitude)} />
      <OffPlanPaymentPlan plans={offPlanData.Payment_Plan} />
      {/* <div className="offplan-video-box" id="offplan-video-box">
        <VideoBox
          title={offPlanData.name}
          bannerimage={offPlanData.video_thumbnail}
          imagetransforms={offPlanData.imagetransforms}
          id={offPlanData.id}
          video={offPlanData.video_link}
        />
      </div> */}

      <OffPlanImageGallery images={offPlanData.upload_images} interior_images={offPlanData?.interior_images} video={offPlanData.video_link} />
      <FloorPlan plans={offPlanData.Floor_Plans} />
      <OffPlanBroucher image={offPlanData.Banner_Img} name={offPlanData?.name} brochure={offPlanData?.upload_brochure} />
      <OffPlanDeveloper block={offPlanData.developer_block} />
      <div id="register-interest-form">
        <RegisterInterset offplan={offPlanData} />
      </div>

      {/* <OtherOffPlan currentOffplan={offPlanData} />
      <Help module="Lets_Work_Together_Module" /> */}
      <div className="details-footer">
        <Footer popularsearch="Sale_With_Bed_Search" />
        {/* <Btn whatsAppMessage={whatsAppMessage} /> */}
      </div>
    </div>
  )
}

export default OffPlanDetailsTemplate

export const pageQuery = graphql`
  query GetOffplan($articleId: ID!) {
    glstrapi {
      offplan(id: $articleId, publicationState: LIVE) {
        Banner_Description
        Banner_Img {
          url
        }
        Details_Accordian {
          Content
          Title
          id
        } 
        permit_number
        permit_verifier
        amenities {
          Module_Description
          Module_Title
          id
          Tile_Image_Items {
            Description
            Title
            id
            Image {
              url
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 320
                    quality: 80
                    layout: FIXED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
          }
        }
        Location_Block {
          Title
          Description
        }
        Payment_Plan {
          Module_Description
          Module_Title
          id
          Module_Items {
            Description
            Price
            Title
            id
          }
        }
        Brochure_Block {
          Title
          Content
          Image {
            alternativeText
            url
          }
        }
        Select_Contact {
          Designation
          URL
          Email
          id
          Phone
          whatsapp_no
          Name
          imagetransforms
          languages
          Image {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 180
                  quality: 80
                  layout: FIXED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
        }
        URL
        bedroom
        brochure
        completion_date
        description
        developement_type
        developer
        epc
        floorplan
        id
        images
        imagetransforms
        location
        name
        payment_plan
        price
        property_type
        latitude
        longitude
        call
        whatsapp
        email
        QR_Code_Image {
          alternativeText
          url
        }
        upload_brochure {
          url
        }
        developer_block {
          id
          Developer
          Content
          Image {
            alternativeText
            url
          }
          Logo {
            alternativeText
            url
          }
        }
        upload_epc {
          url
        }
        upload_floorplan {
          url
        }
        upload_images {
          url
        }
        interior_images {
          url
        }
        video_link
        video_thumbnail {
          url
        }
        Floor_Plans {
          Title
          Bedroom
          Image {
            alternativeText
            url
          }
          Brochure {
            alternativeText
            url
          }
        }
      }
    }
  }
`
