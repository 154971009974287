import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap"
import { useLocation } from "@reach/router"
import "./PlanBanner.scss"
import bannerMob from "../../images/offPlanDetails/plan-banner-376.jpg"
import bannerTab from "../../images/offPlanDetails/plan-banner-768.jpg"
import bannerDesk from "../../images/offPlanDetails/plan-banner-1400.jpg"
import bannerDeskLg from "../../images/offPlanDetails/plan-banner-1800.jpg"
import ButtonGroup from "../ButtonGroup/ButtonGroup"
import { AnimationOnScroll } from "react-animation-on-scroll"
import OffPlanButtonGroup from "./OffPlanButtonGroup/OffPlanButtonGroup"
import { navigate } from "@reach/router"

const OffPlanBanner = (props) => {
  const location = useLocation();
  var backurl = "/off-plan-properies-in-dubai/"
  function backPage() {
    if (window.history?.state?.key) {
      window.history.back(-1)
    }
  }
  var offplan = props.offplan;
  let breadCrumbList = [
    { label: "Home", url: `${process.env.GATSBY_SITE_URL}` },
    { label: `Off Plan Developments in Dubai`, url: `${process.env.GATSBY_SITE_URL}/off-plan-properies-in-dubai/` },
    { label: `${offplan.name} by ${offplan.developer}`, url: `${process.env.GATSBY_SITE_URL}${location.pathname}` }
  ]

  let pos = 0;
  let breadCrumbSchema = []
  breadCrumbList.map((item, i) => {
    pos++;
    breadCrumbSchema.push({
      "@type": "ListItem",
      "position": pos,
      "item":
      {
        "@id": `${item.url}`,
        "name": `${item.label}`,
      }
    })
  })
  return (
    <section className="plan-banner">
      <Helmet>
        <script type="application/ld+json">{`{
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": ${JSON.stringify(breadCrumbSchema, null, 2)}
           }`}
        </script>
      </Helmet>
      {/* <div className="breadcrumbs-wrap">
        <Container>
          <Row>
            <Col>
              <div className="content-wrap">
                <Link state={{completionval:props.setoffplansearches?.completionval,developerval:props.setoffplansearches?.developerval,propertyval:props.setoffplansearches?.propertyval,minpriceVal:props.setoffplansearches?.minpriceVal,maxpriceVal:props.setoffplansearches?.maxpriceVal,minbedVal:props.setoffplansearches?.minbedVal,maxbedVal:props.setoffplansearches?.maxbedVal,bedVal:props.setoffplansearches?.bedVal,areasArr:props.setoffplansearches?.areasArr}} to="/off-plan-properies-in-dubai/" className="back-link">
                  Back to Listings
                </Link>                
               <a href={'javascript:void(0)'} onClick={(e) => backPage() } className="back-btn">
                     Back to Listings
                  </a> 
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <li className="breadcrumb-item"><Link state={{completionval1:props.setoffplansearches?.completionval}} to="/off-plan-properies-in-dubai/">Off Plan Developments in Dubai</Link></li>
                  <Breadcrumb.Item active>
                  {`${offplan.name} by ${offplan.developer}`}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </Col>
          </Row>
        </Container>
      </div>*/}
      <div className="banner-wrap" style={{
        backgroundImage: `url(${offplan.Banner_Img && offplan.Banner_Img.url
          })`,
      }}>
        <div class="banner-overlay"></div>
        <div className="img-wrap">
          <picture>
            {/* <source media="(min-width: 1600px)" srcSet={bannerDeskLg} />
            <source media="(min-width: 1200px)" srcSet={bannerDesk} />
            <source media="(min-width: 768px)" srcSet={bannerTab} /> */}
            <img src={offplan?.Banner_Img?.url} alt="banner" />
          </picture>
        </div>
        <div>
          <div className=" offplan-banner">
            <div className="container banner-contents">
              <h1 className="title">{`${offplan.name}`}</h1>
              <p className="md">{offplan.Banner_Description}</p>
              <p className="td">{offplan.Banner_Description}</p>
              <OffPlanButtonGroup contact={offplan.Select_Contact} offplan={offplan} />
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default OffPlanBanner
