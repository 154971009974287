import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useWindowSize } from "../../hooks/window-size"
import { Container, Row, Col } from "react-bootstrap"
import loadable from "@loadable/component";
import { AnimationOnScroll } from "react-animation-on-scroll"
import Slider from "react-slick"
import Lightbox from "react-image-lightbox"
import "./ImageGallery.scss"
const PlayVideo = loadable(() => import("../PlayVideo/PlayVideo"));

const OffPlanImageGallery = ({ images, interior_images, video }) => {
  const [imags, setImages] = useState([])
  const [isInterior, setIsInterior] = useState(false)
  const [propertyLightImages, setPropertyLightImages] = useState(images && images.map(img => img.url))
  const [windowWidth] = useWindowSize()
  const [isPlay, setPlay] = useState(false);
  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  // Property details images lightbox
  //const propertyLightImages = images && images.map(img => img.url)
  // Property details images lightbox

  const openLightBox = index => {
    setPhotoIndex(index)
    setIsOpen(true)
  }

  useEffect(() => { }, [windowWidth]);

  const changeGallery = interior => {
    setPhotoIndex(0)
    setIsInterior(interior)
    if (interior) {
      setPropertyLightImages(interior_images.map(img => img.url))
    } else {
      setPropertyLightImages(images.map(img => img.url))
    }
  }


  const NextArrow = ({ onClick }) => {
    return (
      <button className="slick-next" onClick={onClick}>
        <i className="icon-pagination-right"></i>
      </button>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <button className="slick-prev" onClick={onClick}>
        <i className="icon-pagination-left"></i>
      </button>
    );
  };
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    //slidesToShow: windowWidth < 992 ? (windowWidth < 768 ? 1 : 2) : 4,
    slidesToShow: 2,
    slidesToScroll: 1,
    //rows: windowWidth < 768 ? 1 : 2,
    //slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <section className="image-gallery" id="image-gallery">
      <Container>
        <Row>
          <Col className="column">
            <div className="heading">
              <h3>Image Gallery</h3>
              <div className="right">
                {images && images.length > 0 &&
                  <a href="javascript:void(0)" className={`type ${!isInterior && "active"}`} onClick={() => { changeGallery(false) }}>Exterior</a>
                }
                {interior_images && interior_images.length > 0 &&
                  <a href="javascript:void(0)" className={`type ${isInterior && "active"}`} onClick={() => { changeGallery(true) }}>Interior</a>
                }
              </div>
            </div>
            {!isInterior && images.length && images.length > 0 && (
              <div className="img-block">
                <Slider {...settings} className="custom-arrow-slider">
                  {images.map((image, index) => {
                    return (
                      <div
                        className="img-card" key={index}>
                        <img src={image.url} alt="gallery" />
                        {index == 0 && video && <button onClick={(e) => { setPlay(true) }} className="video-wrap">
                          <i className="video-btn"></i>
                        </button>}
                      </div>
                    )

                  })}
                </Slider>
              </div>
            )}
            {isInterior && interior_images.length && interior_images.length > 0 && (
              <div className="img-block">
                <Slider {...settings} className="custom-arrow-slider">
                  {interior_images.map((image, index) => (
                    <div
                      className="img-card" key={index}>
                      <img src={image.url} alt="gallery" />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
            <button
              className="btn all_image"
              onClick={() => {
                openLightBox(0)
              }}
            >
              <i className="icon-gallery"></i>
              View all Images
            </button>
          </Col>
        </Row>
      </Container>

      {/* Property image lightbox */}
      {isOpen && (
        <Lightbox
          mainSrc={propertyLightImages[photoIndex]}
          nextSrc={
            propertyLightImages[(photoIndex + 1) % propertyLightImages.length]
          }
          prevSrc={
            propertyLightImages[
            (photoIndex + propertyLightImages.length - 1) %
            propertyLightImages.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + propertyLightImages.length - 1) %
              propertyLightImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
          }
        />
      )}
      {/* Property image lightbox */}
      {isPlay &&
        <PlayVideo isOpen={isPlay} videoId="V8ksSGxo6no" isCloseFunction={setPlay} videourl={video} />
      }
    </section>
  )
}

export default OffPlanImageGallery
