import React from 'react';
import './PlanCard.scss'

const PlanCard = ({ planData }) => {
	return (
		<div className='floor-card'>
			<img src={planData.Image?.url} />
			<div className='desc'>
				<p>{planData.Title}</p>
				{planData && planData.Brochure?.url &&
					<a href={planData.Brochure?.url} target="_blank">Download Brochure</a>
				}
			</div>


		</div>
	)
}

export default PlanCard